import { createApp, provide, h } from 'vue'
import App from './App.vue'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from './apolloClient'
import VueApexCharts from "vue3-apexcharts";
import router from './router/index'
const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },

  render: () => h(App),
})
app.use(VueApexCharts);
app.use(router);
app.mount('#app')
