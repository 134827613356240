<template>
  <section class="app">
    <section class="navbar">
      <router-link to="/">Accueil</router-link>
      <router-link to="/datas">Datas</router-link>
    </section>
    <section class="body">
      <router-view></router-view>
    </section>
    <section class="footer">
      <div class="partners"> 
        <p><b>Partenaires:</b></p>
        <a href="https://lafabriquedesmobilites.fr/" target="_blank">Fabrique des Mobilitésl</a>
        <a href="https://www.ecologie.gouv.fr/direction-generale-des-infrastructures-des-transports-et-mer-dgitm" target="_blank">DGITM/AIT</a>
        <a href="https://www.gouvernement.fr/france-relance" target="_blank">France Relance</a>
        <a href="https://www.inforoutes06.fr/" target="_blank">CIGT 06</a>
        <a href="https://thewalkingnerds.com/" target="_blank">The Walking Nerds</a>
      </div>
    </section>
  </section>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.app {
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  .navbar {
    height: 80px;
    width: 100%;
    background: rgb(255, 224, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    a {
    margin: 0rem 1rem;
    text-decoration: none;
    }
  }
  .footer {
    height: 80px;
    width: 100%;
    background: rgb(255, 224, 0);
    display: flex;
    align-items: center;
    padding: 1em;
    .partners {
      display: flex;
      align-items: center;
      padding: 1em;
      flex-direction: row;
      justify-content: center;
      align-content: center;
      p, a {
        padding: 0px 10px;
      }
    }
  }
  .body {
    min-height: 100vh;
    width: 100%;
    // overflow: auto;
    display: flex;
    justify-content: center;
    // padding-top: 80px;
    padding: 0 30px 80px 30px;
    .center {
        text-align: center;
    }
    .high {
        font-size: 20px;
        font-weight: bold;
    }
  }

    .title {
      font-size: 30px;
      text-transform: uppercase;
      margin: 20px;
      text-align: center;
      background: rgb(157, 205, 215);
      span {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  h1 {
    font-size: 2em;
    padding: 1em 0em;
  }
   h2 {
    padding: 0.5em 0em;
    color: rgb(157, 205, 215);
  }
  p {
    padding-bottom: 10px;
  }
}
</style>
