import { createRouter, createWebHashHistory } from 'vue-router';

const routeOptions = [
  { path: '/', name: 'Home' },
  { path: '/datas', name: 'Datas' },
];

const routes = routeOptions.map(route => {
  return {
    ...route,
    component: () => import(`@/views/${route.name}.vue`)
  }
});

export default createRouter({
    history: createWebHashHistory(),
    routes, 
});

